import { Heading, useTheme } from "@chakra-ui/react"
import React from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Json } from "../../components/Json"

export default function JsonPage() {
  const json = {
    array: [1, 2, 3],
    bool: true,
    object: {
      foo: "bar",
    },
    // immutable: Map({ key: "value" }),
  }

  return (
    <DebugLayout title="Json View">
      <Heading>Normal</Heading>
      <Json>{json}</Json>
      <Heading>Advanced</Heading>
      <Json isAdvanced>{json}</Json>
    </DebugLayout>
  )
}
